import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosHR from 'auth/axiosHR';
import { AUTH_TOKEN } from 'constants/AuthConstant';

export const initialState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN) || null,
};

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const res = await axiosHR().post('login', {
        username: email,
        password: password,
      });

      console.log('LOFIN:', res.data.data.user, res.data.data.token);
      // const response = await FirebaseService.signInEmailRequest(email, password)
      if (res.data.data.user) {
        const token = res.data.data.token;
        localStorage.setItem(AUTH_TOKEN, res.data.data.token);
        localStorage.setItem('user_role', res.data.data.emp_role);
        localStorage.setItem('USER', res.data.data.user);
        localStorage.setItem('EMP_NAME', res.data.data.emp_name);
        localStorage.setItem('PAGES', JSON.stringify(res.data.data.pages));
        localStorage.setItem('REPORTS', JSON.stringify(res.data.data.reports));
        return token;
      } else {
        return rejectWithValue(
          res.data.response.msg?.replace('Firebase: ', '')
        );
      }
    } catch (err) {
      return rejectWithValue(err.response.data.response.msg || 'Error');
    }
  }
);

export const signOut = createAsyncThunk('auth/signOut', async () => {
  // const response = await FirebaseService.signOutRequest();
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem('USER');
  localStorage.removeItem('EMP_NAME');
  localStorage.removeItem('PAGES');
  localStorage.removeItem('REPORTS');
  return;
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = '/';
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = '';
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = '/';
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = '/';
        state.token = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = '/';
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = '/';
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
